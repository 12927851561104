/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50: #e2f4ff,
    100: #bae2ff,
    200: #88d0ff,
    300: #46bdff,
    400: #00adff,
    500: #009dff,
    600: #008eff,
    700: #0079ff,
    800: #0066ff,
    900: #2341e0,
    A100: #ffffff,
    A200: #f2f4ff,
    A400: #bfc9ff,
    A700: #a6b4ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$md-secondary: (
    50: #fee3f0,
    100: #fcb9da,
    200: #fc89c1,
    300: #fe51a7,
    400: #ff0090,
    500: #ff0078,
    600: #ef0075,
    700: #d80070,
    800: #c3006c,
    900: #9a0066,
    A100: #ffffff,
    A200: #fff2f6,
    A400: #ffbfd3,
    A700: #ffa6c2,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$md-light: (
    50: #fdfdfd,
    100: #fbfbfb,
    200: #f9f9f9,
    300: #f6f6f6,
    400: #f4f4f4,
    500: #f2f2f2,
    600: #f0f0f0,
    700: #eeeeee,
    800: #ececec,
    900: #e8e8e8,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
