@import './styles/option-theme';

body {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1c1c1c;
    color: #f2f2f2;
    padding: 0;
    text-align: center;
}
.masterlayout {
    display: contents;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}
